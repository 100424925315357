<template>
  <v-card outlined class="pa-0">
    <v-card-title class="pa-1 subtitle-1">
      <span class="font-weight-medium">{{ item.label }} &nbsp;:</span>
      <v-radio-group dense row class="ml-2" v-model="fields.answer">
        <v-radio :label="item.label" :value="item.value" v-for="(item, index) in item.default_value.options.filter( x => x.isactive)" :key="index"></v-radio>
      </v-radio-group>
      <v-spacer></v-spacer>
      <template v-if="fromChecklistForm">
        <v-btn v-if="!item.default_value.hideComments || !item.default_value.hideAttachments" x-small fab text color="primary" @click=" showSurveySection = !showSurveySection">
          <v-icon>{{ `${ item.expandsurveysection ? 'mdi-arrow-up' : 'mdi-arrow-down'}`}}</v-icon>
        </v-btn>
      </template>
    </v-card-title>
    <template v-if="item.type === 19">
      <v-row class="ma-0 mb-1" justify="end">
        <v-col class="pa-0 pr-1" cols="6" v-if="item.default_value.checkboxFlags.signature">
          <v-text-field outlined v-model="fields.signature" hide-details :label="$t('signature')" dense></v-text-field>
        </v-col>
        <v-col class="pa-0 pr-1" cols="6" v-if="item.default_value.checkboxFlags.date">
         <v-menu v-model="fields.date_menu" :close-on-content-click="false" max-width="290">
          <template v-slot:activator="{ on }">
            <v-text-field dense outlined hide-details v-model="fields.date" :placeholder="item.placeholder"
              clearable prepend-icon="mdi-calendar" :label="$t('date')"
              readonly v-on="on" @click:clear="fields.date = null">
            </v-text-field>
          </template>
          <v-date-picker no-title  v-model="fields.date_value" @input="fields.date = $formatter.parseDate(fields.date_value),fields.date_menu = false"></v-date-picker>
         </v-menu>
        </v-col>
      </v-row>
    </template>
    <v-divider v-if="!item.default_value.hideComments || !item.default_value.hideAttachments || item.html"></v-divider>
    <v-card flat v-if="item.html">
      <v-card-text class="pa-2">
        <div v-html="item.html"></div>
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
    <v-card v-if="showSurveySection">
      <v-card-text  class="pa-0" v-if="!item.default_value.hideComments || !item.default_value.hideAttachments">
        <v-tabs hide-slider centered v-model="tabItem1">
          <v-tab v-if="!item.default_value.hideComments" class="tab-bar mt-2" :style="tabItem1 === 0 ? $formatter.activeTabStyle(systemDetails.themecolor, systemDetails.textcolor) : $formatter.tabStyle(systemDetails.themecolor)">
            <div :class="tabItem1 === 0 ? 'tab--icon' : 'mr-2'" :style="tabItem1 === 0 ? `background-image: linear-gradient(308deg, #ffffff8a, ${systemDetails.themecolor})` : ''">
            <v-icon>mdi-comment-text-outline</v-icon>
            </div>
            {{ $t('comments') }}
          </v-tab>
          <v-tab v-if="!item.default_value.hideAttachments" class="tab-bar mt-2" :style="tabItem1 === 1 ? $formatter.activeTabStyle(systemDetails.themecolor, systemDetails.textcolor) : $formatter.tabStyle(systemDetails.themecolor)">
            <div :class="tabItem1 === 1 ? 'tab--icon' : 'mr-2'" :style="tabItem1 === 1 ? `background-image: linear-gradient(308deg, #ffffff8a, ${systemDetails.themecolor})` : ''">
            <v-icon class="mdi-rotate-270">mdi-attachment</v-icon>
            </div>
            {{ $t('attachments') }}
          </v-tab>
          <v-tab-item v-if="!item.default_value.hideComments">
            <v-divider></v-divider>
            <v-card flat tile>
              <v-card-text>
                <v-textarea hide-details outlined v-model="fields.comments"></v-textarea>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="!item.default_value.hideAttachments">
            <v-divider></v-divider>
            <v-card flat tile>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-file-input :label="$t('attachments')" v-model="fields.attachments"
                    @change="$emit('uploadDocument', item._id, item.name, fields.attachments, item.type)" multiple hide-details outlined dense></v-file-input>
                  </v-col>
                </v-row>
                <v-row :key="reRender" class="pa-2" v-if="fromChecklistForm">
                  <v-col :cols="document._id ? 5 : 6" class="pa-0" v-for="(document, docIndex) in listOfDocuments[item.name]" :key="docIndex">
                    <template v-if="document._id">
                      <v-card flat class="pa-1" height="100">
                        <v-card-text class="text-center pa-1">
                          <template v-if="document.imgObj && document.imgObj.url">
                            <v-img contain height="50" :lazy-src="document.imgObj.url" :src="document.imgObj.url"></v-img>
                          </template>
                          <v-avatar size="52" class="profile" v-else>
                            <v-icon :size="50" :color="document.imgObj.color">{{ document.imgObj.icon }}</v-icon>
                          </v-avatar>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions class="pa-1 ml-3 mb-1">
                          <v-icon size="14" class="pr-2 pl-4" color="primary"  @click="$emit('editDocument',document, item.name);">mdi-lead-pencil</v-icon>
                          <v-icon size="16"  color="info" @click="$emit('downloadDocument',document._id)">mdi-download</v-icon>
                          <v-icon size="16" class="pl-2" color="error" @click="$emit('deleteDocument',document._id, item.name , item.type)">mdi-delete</v-icon>
                        </v-card-actions>
                      </v-card>
                    </template>
                      <template v-else>
                        <template v-for="(document,indx) in document.getFiles">
                          <v-row :key="indx" justify="center">
                            <span class="font-weight-medium ma-2">{{ document.name }}</span>
                            <v-icon color="blue darken-4"  size="20">mdi-file-upload</v-icon>
                          </v-row>
                        </template>
                      </template>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    fields: {
      type: Object,
      default: () => {}
    },
    listOfDocuments: {
      type: Object,
      default: () => {}
    },
    fromChecklistForm: {
      type: Boolean,
      default: false
    },
    expandsurveysection: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tabItem1: 0,
      tabItem2: 0,
      reRender: 0,
      showSurveySection: this.expandsurveysection
    }
  },
  computed: {
    ...mapGetters(['systemDetails'])
  },
  mounted () {
    this.$root.$on('reRender', () => {
      this.reRender++
    })
  }
}
</script>
